<script setup lang="ts">
// types
import type {
  Entity,
  Nullable,
  Step
} from '@revolutionprep/types'
import type { FetchError } from 'ofetch'

// vuetify
import { useTheme } from 'vuetify'

// utilities
import { toEntities } from '@revolutionprep/utils'

// stores
import { useGlobalStore } from '@/store/global'

// composables
import { useEnrollmentWizard } from '@/composables/enrollmentWizard'

/**
 * nuxt app
 * ==================================================================
 */
const { $actor } = useNuxtApp()

/**
 * nuxt runtime config
 * ==================================================================
 */
const config = useRuntimeConfig()

/**
 * route & router
 * ==================================================================
 */
const route = useRoute()
const router = useRouter()

/**
 * vuetify
 * ==================================================================
 */
const vuetifyTheme = useTheme()

/**
 * state
 * ==================================================================
 */
const capturedError = ref<Nullable<Error>>(null)
const mailToSubject = ref('Back-Up Care Service Request')
const renderError = ref(false)

/**
 * stores
 * ==================================================================
 */
const globalStore = useGlobalStore()
const { isLoading } = storeToRefs(globalStore)

const organizationStore = useOrganizationStore()
const { organizationInfo } = storeToRefs(organizationStore)

const tutorPackageTranscriptStore = useTutorPackageTranscriptStore()
const { tutorPackageTranscript } = storeToRefs(tutorPackageTranscriptStore)

/**
 * composables
 * ==================================================================
 */
const { doHandleError } = useErrorHandler()

const {
  affiliateReservation,
  error,
  fetchTutorPackageTranscript,
  lastStepCompleted
} = useEnrollmentWizard()

/**
 * computed
 * ==================================================================
 */
const actor = computed(() => {
  return $actor.core.actor.value
})

const isLoggedIn = computed(() => {
  return Boolean($actor.core.isLoggedIn.value && actor.value)
})

const isSpoofing = computed(() => {
  return Boolean($actor.core.spoofing.value)
})

// styles
const containerClasses = computed(() => {
  return isLoggedIn.value
    ? 'container mx-auto'
    : 'container pa-0 d-flex align-center justify-center'
})
const mainStyles = computed(() => {
  return {
    background: `${vuetifyTheme.current.value.colors.backgroundgrey}`,
    height: `calc(100% - ${isSpoofing.value ? '128px' : '70px'})`
  }
})

// affiliate reservations
const affiliateLogoUrl = computed(() => {
  return affiliateReservation.value?.affiliate?.logoUrl || ''
})
const affiliateName = computed(() => {
  return affiliateReservation.value?.affiliate?.name || ''
})
const affiliateReservationNumber = computed(() => {
  return (
    tutorPackageTranscript.value?.affiliateReservation?.reservationNumber ||
    'N/A'
  )
})
const isAffiliateReservationPending = computed(() => {
  return affiliateReservation.value?.status === 'pending'
})
const mailToBody = computed(() => {
  return `Reservation number: ${
    affiliateReservationNumber.value
  }%0D%0A%0D%0APlease add any additional information below:
  %0D%0A%0D%0A%0D%0A`
})

// enrollment wizard steps
const currentStep = computed(() => {
  const routeFullPath = route.fullPath
  const currentStep = steps.value.find((step) => {
    return step.routePath === routeFullPath
  })
  return currentStep?.stepNumber || 0
})
const enrollmentWizardHeading = computed(() => {
  return $actor.core.storage.getState('enrollmentWizardHeading')
})
const enrollmentWizardStep = computed(() => {
  return $actor.core.storage.getState<number>('enrollmentWizardStep')
})
const steps = computed(() => {
  return [
    {
      routePath: `/bright-horizons/enrollment-wizard/${tutorPackageTranscriptId.value}`,
      stepNumber: 1,
      stepTitle: 'Account'
    },
    {
      routePath: `/bright-horizons/enrollment-wizard/${tutorPackageTranscriptId.value}/subject`,
      stepNumber: 2,
      stepTitle: 'Subject'
    },
    {
      routePath: `/bright-horizons/enrollment-wizard/${tutorPackageTranscriptId.value}/availability`,
      stepNumber: 3,
      stepTitle: 'Availability'
    },
    {
      routePath: `/bright-horizons/enrollment-wizard/${tutorPackageTranscriptId.value}/tutor`,
      stepNumber: 4,
      stepTitle: 'Tutor'
    },
    {
      routePath: `/bright-horizons/enrollment-wizard/${tutorPackageTranscriptId.value}/submit`,
      stepNumber: 5,
      stepTitle: 'Finish'
    }
  ]
})
const stepsEntities = computed(() => {
  return toEntities(steps.value, 'stepNumber') as Entity<Step>
})

// tutor package transcript
const tutorPackageTranscriptId = computed(() => {
  return Number(route.params.tutorPackageTranscriptId)
})

/**
 * methods
 * ==================================================================
 */
function doConfirmRouteValidation () {
  const step1RoutePath = stepsEntities.value[1].routePath
  const lastValidRoute =
    stepsEntities.value[lastStepCompleted.value + 1]?.routePath
  const isStepInvalid = currentStep.value > lastStepCompleted.value + 1
  const isNotAllowedOnCompletePage = (lastStepCompleted.value !== 5) &&
    route.fullPath.includes('complete')

  if (isStepInvalid || isNotAllowedOnCompletePage) {
    if (lastValidRoute) {
      router.push(lastValidRoute)
    } else if (step1RoutePath) {
      router.push(step1RoutePath)
    }
  }
}

/**
 * lifecycle hooks
 * ==================================================================
 */
onBeforeMount(() => {
  isLoading.value = true

  if (
    !isAffiliateReservationPending.value &&
    (lastStepCompleted.value === 5)
  ) {
    router.push(`/bright-horizons/enrollment-wizard/${
      tutorPackageTranscriptId.value
    }/complete`)
  }

  doConfirmRouteValidation()
})

onMounted(() => {
  // set loading false to hide skeleton-loaders
  isLoading.value = false
})

/**
 * data fetching
 * ==================================================================
 */
await useLazyAsyncData('enrollment-wizard-layout',
  async () => {
    try {
      await fetchTutorPackageTranscript(tutorPackageTranscriptId.value)
    } catch (errorResponse) {
      error.value = await doHandleError(errorResponse as FetchError)
    }
  }
)

/**
 * lifecycle hooks
 * ==================================================================
 */
onErrorCaptured((
  err: Error,
  _instance: ComponentPublicInstance | null,
  info: string
) => {
  doHandleError(err)
  capturedError.value = err
  renderError.value = info === 'render'
  return false
})
</script>

<template>
  <v-app>
    <div
      v-if="isLoggedIn"
      style="height: 100%;"
    >
      <transition name="fade">
        <div
          v-if="isLoading"
          class="d-flex flex-column justify-space-between"
          style="height: 100vh;"
        >
          <div>
            <v-skeleton-loader
              type="list-item"
              width="100vw"
              height="56px"
              style="border-bottom: 0.75px solid #E4E4E4; border-radius: 0px; z-index: 6;"
            />
            <v-skeleton-loader
              type="heading"
              width="100vw"
              style="padding: 12px;"
            />
            <v-skeleton-loader
              type="article, article, article, article"
              width="100vw"
            />
          </div>
          <div>
            <v-skeleton-loader
              type="list-item"
              width="100vw"
              height="56px"
              style="border-top: 0.75px solid #E4E4E4; border-radius: 0px; z-index: 6;"
            />
          </div>
        </div>
      </transition>
      <template v-if="!isLoading">
        <client-only>
          <LazyAppBarEnrollmentWizard
            :affiliate-logo-url="affiliateLogoUrl"
            :affiliate-name="affiliateName"
          />
          <ToolbarSpoof class="pt-14" />
        </client-only>
        <v-main :style="mainStyles">
          <v-container
            :class="containerClasses"
            :fill-height="!isLoggedIn"
          >
            <div v-if="!renderError">
              <header class="d-flex">
                <h1
                  v-if="enrollmentWizardHeading"
                  class="text-h5 font-weight-bold mb-4"
                >
                  {{ enrollmentWizardHeading }}
                </h1>
              </header>
              <LazyStepper
                v-if="enrollmentWizardStep"
                :steps="steps"
                :value="enrollmentWizardStep"
              />
            </div>

            <slot v-if="!isLoading && !renderError" />

            <lazy-r-error-display
              v-else-if="renderError && capturedError"
              :error="capturedError"
              :home-url="stepsEntities[1].routePath"
            />
          </v-container>
        </v-main>
        <client-only>
          <lazy-r-footer
            :app="isLoggedIn"
            :company="organizationInfo"
            :logged-in="isLoggedIn"
            :settings="config"
            :support-url="`mailto:backupcare@revolutionprep.com?subject=${
              mailToSubject}&body=${mailToBody}`"
          />
        </client-only>
      </template>
    </div>
  </v-app>
</template>

<style scoped>
.container {
  height: 100%;
}

@media (min-width: 600px) {
  .container {
    width: 90vw;
  }
}

@media (max-width: 600px) {
  .container {
    width: 95vw;
  }
}
</style>
